import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/app.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from "@angular/router"
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService, private router: Router) { }
  navItems: any
  imageUrl = `/assets/Logοo.png`;
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  trigger: any;
  navbarDropdown = `EL`;
  iconUrl = `../../../assets/greece.png`;
  boxItems: any[] = [];
  latestNews: any[] = []
  countries = [
    { name: 'EL', code: 'EL', img: 'greece.png' },
    { name: 'EN', code: 'US', img: 'united-kingdom.png' },

  ];
  url = '/page/6102'
  faFacebook = faFacebook
  faEnvelope = faEnvelope

  selectedCountry = { name: 'EL', code: 'EL', img: 'greece.png' }

  async ngOnInit() {
    let language: string;
    language = this.translate.currentLang === undefined ? 'el' : this.translate.currentLang;
    await this.fetchMenus(language);

    let latestNews: any = await this.api.getCategory2('4', 6, 'el', 1).toPromise()
    this.latestNews = latestNews.data
    for (let index = 0; index < this.latestNews.length; index++) {
      const element = this.latestNews[index];
      var date = new Date(element.attributes.published);
      var n = date.toLocaleDateString();
      element.attributes.published = n

    }

    //workaround for the strapi bug error throwing unauthorized sometimes
    if (this.navItems.length == 0) {
      await this.fetchMenus(language);
      this.ngOnInit()
    }

    //react to language changing events
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      language = event.lang;
      await this.fetchMenus(language);

      //redirect to the homepage. We don't want users to see pages and elements that were fetched with the other language option
      this.router.navigate(['']);

    })



    // document.onkeydown = (e) => {
    //   if (e.keyCode === 9) {
    //     var currentElementWithFocus = document.activeElement; //the element that is currently focused

    //     if (this.boxItems.length > 0) {
    //       //if we are at the lastly inserted LI item, and user presses Tab, we want to move on the next menu item and hide the current menu item
    //       //this menu item can be a menu like ΕΠΙΜΕΛΗΤΗΡΙΟ or a subMenu like ΓΕΜΗ that contains sub-subMenus
    //       if (currentElementWithFocus.isSameNode(this.boxItems[this.boxItems.length - 1])) {  
    //         ////the hierachy of items looks like this: 
    //         //currentElementWithFocus --> the LI that is currently focused
    //         //currentElementWithFocus.parentElement --> the SPAN that contains the LI
    //         //currentElementWithFocus.parentElement.parentElement --> the UL that contains all SPAN's and thus all LI's menu items
    //         currentElementWithFocus.parentElement.parentElement.classList.remove("show");
    //       }
    //     }
    //   }
    // }

  }


  switchLanguage(event: any) {
    console.log(event)
    if (event.value.name === "EL") {
      this.translate.use('el');
      this.url = '/page/6102'
    } else if (event.value.name === "EN") {
      this.translate.use('en');
      this.url = '/page/6103'
    }

  }


  open(event: any) {
    event.target.nextElementSibling.classList.add("show")
  }

  close2(event: any) {

    if (event.target.children[1].classList[1]) {
      event.target.children[1].classList.remove("show")
    }
  }

  emit() {
    this.toggle.emit(null);
  }

  close(child: any, locationMenuTrigger: any) {
    this.trigger = locationMenuTrigger
    if (child.explicitOriginalTarget.classList[1] == 'menu-button') {
      locationMenuTrigger.closeMenu()
    }
    // child.originalTarget.parentNode
  }

  async fetchMenus(locale: string) {
    let menus: any = await this.api.getMenus(locale).toPromise();
    type menuItem = { attributes: { rank: number } }


    menus.data.forEach((element: { children: any[], id: number }) => {
      //we want to gather in a property named children, the menu items that have the particular element as parent
      element.children = [];

      menus.data.forEach((obj: { attributes: { parent: { data: { id: number } } } }) => {
        if (obj.attributes.parent.data !== null && obj.attributes.parent.data.id === element.id) {
          //the obj object is child of the element obj
          element.children = [...element.children, obj];
        }
      })

      //sort the children of each element based on rank
      element.children.sort((a: menuItem, b: menuItem) => {
        return (a.attributes.rank > b.attributes.rank) ? 1 : (b.attributes.rank > b.attributes.rank) ? -1 : 0;
      })
    })

    //sort the children of each menu based on rank
    menus.data.sort((a: menuItem, b: menuItem) => {
      return (a.attributes.rank > b.attributes.rank) ? 1 : (b.attributes.rank > b.attributes.rank) ? -1 : 0;
    })

    //put at navItems all menu items that don't have a parent. Ie the main menu items.
    this.navItems = [];
    menus.data.forEach((element: { attributes: { parent: { data: any } } }) => {
      if (element.attributes.parent.data === null) {
        this.navItems.push(element);
      }
    })
  }

  handleTabMenuWithSubMenus(e: any) {
    const targetElement: any = e.target;
    const subMenus = targetElement.children[1];

    //show the submenus by making the ul inside of targetElement visible, by adding the following styles
    if (subMenus) {
      subMenus.classList.add("activeFocus");
      subMenus.classList.add("show");
    }

    //check if you entered a new menu item. You enter a new menu item when the previous sibling of the target's parent is ul. If it's true,
    //then hide that ul by remove the styles you added before. This code catches the occassions that document.onkeydown doesn't, ie when the last subMenu
    //isn't the last menu item that got inserted in boxItems. This is happening if there is a subMenu with sub-subMenus above.
    if (targetElement.parentElement.previousSibling && targetElement.parentElement.previousSibling.tagName === "UL") {
      targetElement.parentElement.previousSibling.children[0].children[1].classList.remove("show");
    }


    if (subMenus) {
      //the menu that we are currently are, has subMenus. We want to put the LI element of each subMenu at boxItems
      const children = subMenus.childNodes;
      let items = this.boxItems; //current boxItems

      children.forEach((item: { children: any[] }) => {
        if (item.children && item.children[0] && item.children[0].tagName === "LI") {
          items.push(item.children[0]);
        }
      })
      this.boxItems = items;

    }
  }


  handleTabMenuWithoutSubMenus(e: any) {
    //we entered a new menu that does not have any subMenus. We only want to close the previous menu, if only it has subMenus
    if (e.target.parentElement.previousElementSibling && e.target.parentElement.previousElementSibling.children[0].children[1]) {
      e.target.parentElement.previousElementSibling.children[0].children[1].classList.remove("show");
    }
  }

  onKey(e: any) {
    let x = e.target.children[1];  // 
    x && x.classList.add('activeFocus');
    x && x.classList.add('show');

    if (e.target.parentElement.previousSibling && e.target.parentElement.previousSibling.tagName == 'UL') {
      e.target.parentElement.previousSibling.children[0].children[1].classList.remove('show');

    }
    if (x) {
      var children = x.childNodes;

      let bb = this.boxItems

      children.forEach(function (item: { children: any[]; }) {

        if ((item.children && item.children[0] && item.children[0].tagName == 'LI')) {
          bb = [...bb, item.children[0]]
        }
      });
      this.boxItems = bb

    }
  }

  onKey2(e: any) {
    e.target.parentNode.previousElementSibling.children[0].children[1].classList.remove('show');
  }

  detail(element) {

    let x = element.getElementsByClassName("dropdown-menu")
    for (var i = 0; i < x.length; i++) {
      var thisDayDiv = x[i];
      if (thisDayDiv.classList.contains("show")) {
        thisDayDiv.classList.toggle('show')
      }
    }

  }
}
