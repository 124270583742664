import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../app.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-chamber-members',
  templateUrl: './chamber-members.component.html',
  styleUrls: ['./chamber-members.component.css']
})
export class ChamberMembersComponent implements OnInit {


  single = [];

  single2 = [];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#755d38', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Ειδικά Μητρώα';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Μέλη';

  graphs: any
  isBrowser: any

  constructor(private api: ApiService, @Inject(PLATFORM_ID) private platformId: object,) {
    this.isBrowser = isPlatformBrowser(platformId);

  }

  async ngOnInit() {
    console.log('br', this.isBrowser)
    if (this.isBrowser == true) {
      let links: any = await this.api.getGraphs('el').toPromise();

      this.graphs = links.data.attributes
      this.graphs.date = new Date(this.graphs.date).toLocaleDateString('el-GR', { timeZone: 'Europe/Athens' })
      this.single = [
        {
          "name": "Εμπορικό Τμήμα",
          "value": this.graphs.emporiko_tmima
        },
        {
          "name": "Μεταποιητικό Τμήμα",
          "value": this.graphs.metapoiitiko_tmima
        },
        {
          "name": "Τμήμα Υπηρεσιών",
          "value": this.graphs.tmima_upiresion
        },
      ]

      this.single2 = [
        {
          "name": "Ασφαλιστικοί Σύμβουλοι ",
          "value": this.graphs.asfa_sumvouloi
        },
        {
          "name": "Εξαγωγείς",
          "value": this.graphs.eksagogeis
        },
        {
          "name": "Μεσίτες Αστικών Συμβάσεων",
          "value": this.graphs.mesites
        },
        {
          "name": "Ασφαλιστικοί Πράκτορες",
          "value": this.graphs.asfa_praktores
        },
        {
          "name": "Αργυροχρυσοχόοι",
          "value": this.graphs.arguroxrusoxooi
        },
        {
          "name": "Συντονιστές Ασφαλιστικών Συμβούλων",
          "value": this.graphs.suntonistes
        },
      ];
    }
  }

  onSelect(event) {
    // console.log(event);
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

}
