import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, Injector, NgModule, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorInterceptor } from './error.interceptor';
import { MenuComponent } from './components/menu/menu.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageComponent } from './page/page.component';
import { MetaModule } from '@ngx-meta/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { HomepageComponent } from './homepage/homepage.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NguCarouselModule } from '@ngu/carousel';
import { TimelineModule } from 'primeng/timeline';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { GalleriaModule } from 'primeng/galleria';
import { DividerModule } from 'primeng/divider';
import { ListboxModule } from 'primeng/listbox';
import { CarouselModule } from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BannersComponent } from './components/banners/banners.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { SearchComponent } from './search/search.component';
import { ChamberMembersComponent } from './chamber-members/chamber-members.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DropdownModule } from 'primeng/dropdown';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { Observable, of } from 'rxjs';
import { NgMarqueeModule } from 'ng-marquee';
import { CalendarModule } from 'primeng/calendar';

import * as translationEn from './../assets/i18n/en.json';
import * as translationEl from './../assets/i18n/el.json';

const TRANSLATIONS = {
  en: translationEn,
  el: translationEl,
};

export class JSONModuleLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang]);
  }
}

export function HttpLoaderFactory(http: HttpClient, platform: any) {
  console.log(isPlatformBrowser(platform));
  if (isPlatformBrowser(platform) === true) {
    return new TranslateHttpLoader(http);
  }
  else {
    return new JSONModuleLoader();
  }
}

//the configuration of the cookie
const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": environment.cookieDomain
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#23527c",
      "text": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000"
    }
  },
  "type": "opt-out",
  "content": {
    "message": "Αυτός ο ιστότοπος χρησιμοποιεί cookies για να εξασφαλίσει ότι θα έχετε την καλύτερη δυνατή εμπειρία.",
    "dismiss": "Το καταλαβα!",
    "allow": "Αποδοχή cookies",
    "deny": "Άρνηση",
    "link": "Μάθετε περισσότερα",
    "policy": "Πολιτική Cookies",
    "href": "https://ec.europa.eu/info/cookies_el",
  }
};
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SearchBarComponent,
    PageComponent,
    HomepageComponent,
    FooterComponent,
    BannersComponent,
    CategoryComponent,
    ContactComponent,
    SearchComponent,
    ChamberMembersComponent,
    NewsletterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    MatSnackBarModule,
    DividerModule,
    HttpClientModule,
    FormsModule,
    CarouselModule,
    ListboxModule,
    NgxSpinnerModule,
    PaginatorModule,
    TimelineModule,
    AccordionModule,
    NgMarqueeModule,
    CardModule,
    GalleriaModule,
    FontAwesomeModule,
    NgxMasonryModule,
    MetaModule,
    CalendarModule,
    ShareButtonsModule,
    ShareIconsModule,
    DropdownModule,
    NgxChartsModule,
    NguCarouselModule,
    TranslateModule.forRoot({
      defaultLanguage: 'el',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, PLATFORM_ID]
      }
    }),
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgbModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
    deps: [Injector],
  },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: Object) { }
}

