<div class="container-fluid">
    <div class="row" style="justify-content: center;">
        <div *ngFor="let item of images" class="col-md-12" style="justify-content:center;align-items:center;display:flex;margin-top:2rem">
            <div>
                <a [href]="item.attributes.url" target="_blank">
                    <img 
                        [src]="item.attributes.banner.data.attributes.url" 
                        [alt]="item.attributes.title" 
                        class="img-fluid" style="width: 11rem;margin-top:2vh; max-height: 11rem;"
                    />
                </a>
            </div>
        </div>
    </div>
</div>
