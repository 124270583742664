import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { PrimeIcons } from 'primeng/api';
import { ApiService } from '../app.service';
import { environment } from 'src/environments/environment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import isbot from 'isbot';
import { isPlatformBrowser } from '@angular/common';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from "luxon"
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  apiUrl: string = environment.apiUrlPublic;

  constructor(private api: ApiService, private translate: TranslateService, private ccService: NgcCookieConsentService, private spinner: NgxSpinnerService,
    private title: Title, private modalService: NgbModal, private sanitizer: DomSanitizer, @Inject(PLATFORM_ID) private platformId: object, private router: Router,) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '360px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.isBrowser = isPlatformBrowser(platformId);
  }
  carouselItems: any[any] = [1, 2, 3, 4];
  mainItems: any[] = [...this.carouselItems]
  isBrowser: any

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
    load: 3,
    slide: 1,
    speed: 400,
    interval: { timing: 1000, initialDelay: 0 },
    loop: true,
    touch: true,
    velocity: 0.2,
    vertical: {
      enabled: true,
      height: 600
    }
  }
  responsiveOptions: any;
  images: any[] = []; //carousel at the upper part of page. Depicts the land of Argolida
  images2: any[] = []; //carousel at the middle part of page. Display free business services
  images3: any[] = []; //carousel at the bottom part of page. Display useful links

  calendar = PrimeIcons.CALENDAR
  circle = PrimeIcons.CIRCLE
  events: any[] = [];
  links: any = [];
  latestNews: any[] = [];
  accordionTabHeader: string = '';
  language = 'el'
  popupData: any
  body: any
  @ViewChild('content', { static: true }) public content: ElementRef;
  faBolt = faBolt
  date: Date[] | undefined;
  public locale = 'EL';
  eventDates: any = [];


  async ngOnInit() {
    this.spinner.show();
    this.title.setTitle('ARCCI | ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΡΓΟΛΙΔΑΣ');
    if (this.isBrowser == false) {
      this.language = 'el'
    }
    else {
      this.language = this.translate.currentLang === undefined ? "el" : this.translate.currentLang;
    }

    let popup: any = await this.api.gePopup(this.language).toPromise();
    this.popupData = popup.data
    if (this.isBrowser == false) {
      this.body = this.sanitizer.bypassSecurityTrustHtml(this.popupData.attributes.content)
    }
    else if (this.isBrowser == true) {

      let parser = new DOMParser();
      let doc: any = parser.parseFromString(this.popupData.attributes.content, "text/html");
      let images = doc.getElementsByTagName('img');

      for (let i = 0; i < images.length; i++) {
        images[i].classList.add('img-fluid');
      }

      let tables = doc.getElementsByTagName('table');


      for (let i = 0; i < tables.length; i++) {

        tables[i].style.all = 'initial'
        tables[i].classList.add('table');
        tables[i].parentNode.classList.add('table-responsive')
      }

      this.body = new XMLSerializer().serializeToString(doc)


    }

    if (this.popupData.attributes.title !== null) {
      this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    }
    //get links
    let links: any = await this.api.getLinks(this.language).toPromise();
    this.links = links.data;

    // //get userful links
    // let usefulLinks: any = await this.api.getUsefulLinks().toPromise();
    // this.images3 = usefulLinks.data;
    // //create the full path for each image
    // this.images3.forEach((image: { attributes: { banner: { data: { attributes: { url: string } } } } }) => {
    //   image.attributes.banner.data.attributes.url = this.apiUrl + image.attributes.banner.data.attributes.url;
    // })

    //get pages with id ekdiloseis --> ekdhlwseis
    this.events = await this.fetchElements(this.links.attributes.ekdiloseis, 3);

    var date = new Date();
    var firstDay = new Date().toISOString().slice(0, 8) + '01';
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];


    this.eventDates = await this.getEvents(firstDay, lastDay)

    this.images = await this.fetchSliders()

    //get banners
    this.images2 = await this.fetchBanners();

    //get pages with id latest_news --> teleutaia nea
    this.latestNews = await this.fetchElements(this.links.attributes["latest_news"], 3);

    this.setAccordionTabHeader();

    //react to the language changing event
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      this.language = event.lang;

      //get links
      links = await this.api.getLinks(this.language).toPromise();
      this.links = links.data;

      //get pages with id latest_news --> teleutaia nea
      this.latestNews = await this.fetchElements(this.links.attributes["latest_news"], 3);

      //get pages with id ekdiloseis --> ekdhlwseis
      this.events = await this.fetchElements(this.links.attributes.ekdiloseis, 3);



      // this.setAccordionTabHeader();
    })
    this.spinner.hide();
  }

  carouselTileLoad(data: any) {
    let arr = this.carouselItems;
    this.carouselItems = [...this.carouselItems, ...this.mainItems];
  }

  //the function used to fetch elements from the strapi. Ie fetch pages of type: 'epikairothta', 'diagonismoi'
  async fetchElements(id: number, limit: number): Promise<any> {

    let obj: any = await this.api.getPagesLimit(id, limit, this.language).toPromise();
    //array with the elements. We want to keep the necessary properties of each element
    let box: any[] = obj.data;

    box.forEach((element: { attributes: { cover: any, published: any, temporary: any, date_from: any, date_to: any, post: any, body: any, short_description: string } }) => {
      //edit the publication time
      let date = new Date(element.attributes.published);
      let localeDate = date.toLocaleDateString();
      element.attributes.published = localeDate;

      //build the full path of image if it exists, otherwise set a random photo
      if (element.attributes.cover.data) {
        element.attributes.cover = this.apiUrl + element.attributes.cover.data.attributes.url;
      } else {
        // let x = this.randomIntFromInterval(0, 3);
        element.attributes.cover = `/assets/header01.jpg`;
      }

      //check if the element is temporary. If true, then determine if it should be rendered.
      // if (element.attributes.temporary) {
      //   let currentDate = new Date().toISOString();
      //   let fromDate = new Date(element.attributes.date_from).toISOString();
      //   let toDate = new Date(element.attributes.date_to).toISOString();
      //   element.attributes.post = currentDate > fromDate && currentDate < toDate;
      // } else {
      //   element.attributes.post = true;
      // }
    })

    return box;
  }

  async fetchSliders(): Promise<any> {
    let sliders: any = await this.api.getSliders().toPromise();
    let images: any = sliders.data;
    images.forEach((image: any) => {
      //image.attributes.image.data.attributes.url = this.apiUrl + image.attributes.image.data.attributes.url; -->swsth grammh

      //to data einai lista apo object kai oxi object. gia na trexei, metatrepw to data se object.Prepei na diagraftei 
      //kai na antikatastathei me thn 1h grammh otan ftiaxtei to problhma sto strapi
      let data = image.attributes.image.data;
      image.attributes.image.data = {}
      image.attributes.image.data.attributes = {};
      image.attributes.image.data.attributes.url = this.apiUrl + data[0].attributes.url;
    })
    return images;
  }

  async fetchBanners(): Promise<any> {
    let banners: any = await this.api.getBanners().toPromise();
    let images: any = banners.data;
    //create the full path for each banner
    images.forEach((image: { attributes: { banner: { data: { attributes: { url: string } } } } }) => {
      image.attributes.banner.data.attributes.url = this.apiUrl + image.attributes.banner.data.attributes.url;
    })
    return images;
  }

  randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  setAccordionTabHeader() {
    if (this.translate.currentLang === "en") {
      this.accordionTabHeader = "Electronic Services for Businesses through the Central Union of Chambers of Greece";
    } else {
      this.accordionTabHeader = "Ηλεκτρονικές Υπηρεσίες για Επιχειρήσεις μέσω της Κεντρικής Ένωσης Επιμελητηρίων Ελλάδας";
    }
  }

  async getEvents(firstDay, lastDay) {

    let monthEvents: any = await this.api.getEvents(this.links.attributes.ekdiloseis, this.language, firstDay, lastDay).toPromise()


    for (let index = 0; index < monthEvents.data.length; index++) {
      const element = monthEvents.data[index];
      element.day = element.attributes.event_date.slice(-2)
      element.year = element.attributes.event_date.slice(0, 4)
      element.month = element.attributes.event_date.slice(5, 7)

    }

    return monthEvents.data
  }

  async onMonthChange(date) {
    console.log('month change', date)


    let dt = new Date(`${date.year}-${date.month}-01`).toISOString()

    const lastDayJan = DateTime.fromISO(dt)
      .endOf('month')
      .toString().slice(0, 10)
    const firstDay = DateTime.fromISO(dt)
      .startOf('month')
      .toString().slice(0, 10)
    console.log(firstDay)
    console.log(lastDayJan)
    this.eventDates = await this.getEvents(firstDay, lastDayJan)
  }

  async onSelect(datee) {


    var date = datee.toISOString()
    let formattedDate = DateTime.fromISO(date.toString());
    console.log(formattedDate.toString().slice(0, 10))
    let d = formattedDate.toString().slice(0, 10)

    let event = await this.getEvents(d, d)
    if (event.length > 0) {
      this.router.navigate([`/page/${event[0].id}`]);
    }


  }

  checkDateForHoliday(date: any) {

    let isEvent = false
    for (let index = 0; index < this.eventDates.length; index++) {
      const element = this.eventDates[index];
      date.day = date.day.toString()
      if (date.day.length == 1) {
        date.day = `0${date.day}`
      }

      // console.log(element.day, `${date.day}`)
      if (element.day == `${date.day}`) {
        // console.log('trueeeeeeeee')
        isEvent = true
      }

    }

    return isEvent
  }


}
