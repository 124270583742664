<div class="container">
    <div class="row">
        <div class="espa col-md-3">
            <a routerLink="{{url}}"><img alt="chamber-logo" src="../../../assets/banner espa.jpg" class="img-fluid"/></a>
        </div>
        <div class="col-md-3" style="background: var(--base-white, #FFF);">
        </div>
        <div class="col-md-6" style="display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;
        background: var(--base-white, #FFF);
        flex: 1 0 0;margin-top: 1rem; ">
          <app-search-bar></app-search-bar>
            <a href="https://www.facebook.com/epimelitirioargolidas/"><fa-icon [icon]="faFacebook"></fa-icon></a>
            <a href="mailto:ebear@otenet.gr"><fa-icon [icon]="faEnvelope"></fa-icon></a>
        </div>

    </div>
    <div class="row">
        <div class="col-md-12" style="background:#026AA2">
            <nav class="navbar navbar-expand-lg navbar-light" id="main_navbar"
                style="border-bottom: 1px solid #373636;">
                <a routerLink="/"><img alt="chamber-logo" src="../../../assets/Logοo.png" class="img-fluid"/></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div #toggler class="collapse navbar-collapse menubar" id="navbarSupportedContent"
                    (click)="detail(toggler)">
                    <ul class="navbar-nav mr-auto" *ngFor="let item of navItems">
                        <li class="nav-item" *ngIf="item.children.length == 0" tabindex="0" data-toggle="collapse"
                            (keyup.Tab)="onKey2($event)">
                            <a *ngIf="item.attributes.url" class="nav-link"
                                routerLink={{item.attributes.url}}>{{item.attributes.name}}</a>
                            <a *ngIf="item.attributes.category.data" class="nav-link"
                                routerLink="/category/{{item.attributes.category.data.id}}/1">{{item.attributes.name}}</a>
                            <a *ngIf="item.attributes.page.data" class="nav-link"
                                routerLink="/page/{{item.attributes.page.data.id}}">{{item.attributes.name}}</a>
                            <a *ngIf="!item.attributes.page.data && !item.attributes.category.data && !item.attributes.url"
                                class="nav-link">{{item.attributes.name}}</a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="item.children.length != 0" data-toggle="collapse"
                            (mouseleave)="close2($event)" tabindex="0" (keyup.Tab)="onKey($event)">
                            <a *ngIf="item.attributes.url" class="nav-link dropdown-toggle" id="navbarDropdown"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)" routerLink="{{item.attributes.url}}">
                                {{item.attributes.name}}
                            </a>
                            <a *ngIf="item.attributes.category.data" class="nav-link dropdown-toggle"
                                id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" (mouseover)="open($event)"
                                routerLink="/category/{{item.attributes.category.data.id}}/id">
                                {{item.attributes.name}}
                            </a>
                            <a *ngIf="item.attributes.page.data" class="nav-link dropdown-toggle" id="navbarDropdown"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)" routerLink="/page/{{item.attributes.page.data.id}}">
                                {{item.attributes.name}}
                            </a>
                            <a *ngIf="!item.attributes.page.data && !item.attributes.category.data && !item.attributes.url"
                                class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)">
                                {{item.attributes.name}}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <span *ngFor="let subitem of item.children">
                                    <li *ngIf="!subitem.children || subitem.children.length == 0" tabindex="0">
                                        <a *ngIf="subitem.attributes.url && subitem.attributes.url !='/chamber-members'"
                                            class="dropdown-item" href="{{subitem.attributes.url}}"
                                            target="_blank">{{subitem.attributes.name}}</a>
                                        <a *ngIf="subitem.attributes.url && subitem.attributes.url =='/chamber-members'"
                                            class="dropdown-item"
                                            routerLink="{{subitem.attributes.url}}">{{subitem.attributes.name}}</a>
                                        <a *ngIf="subitem.attributes.category.data" class="dropdown-item"
                                            routerLink="/category/{{subitem.attributes.category.data.id}}/1">{{subitem.attributes.name}}</a>
                                        <a *ngIf="subitem.attributes.page.data" class="dropdown-item"
                                            routerLink="/page/{{subitem.attributes.page.data.id}}">{{subitem.attributes.name}}</a>
                                        <a *ngIf="!subitem.attributes.page.data && !subitem.attributes.category.data && !subitem.attributes.url"
                                            class="dropdown-item">{{subitem.attributes.name}}</a>
                                    </li>
                                    <li *ngIf="subitem.children && subitem.children.length > 0"
                                        class="nav-item dropdown" (mouseleave)="close2($event)" tabindex="0">
                                        <a *ngIf="subitem.attributes.url" class="dropdown-item dropdown-toggle"
                                            routerLink="{{subitem.attributes.ur}}" id="navbarDropdown1" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            (mouseover)="open($event)">{{subitem.attributes.name}}
                                        </a>
                                        <a *ngIf="subitem.attributes.category.data"
                                            class="dropdown-item dropdown-toggle"
                                            routerLink="/category/{{subitem.attributes.category.data.id}}/1"
                                            id="navbarDropdown1" role="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"
                                            (mouseover)="open($event)">{{subitem.attributes.name}}
                                        </a>
                                        <a *ngIf="subitem.attributes.page.data" class="dropdown-item dropdown-toggle"
                                            routerLink="/page/{{subitem.attributes.page.data.id}}" id="navbarDropdown1"
                                            role="button" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" (mouseover)="open($event)">{{subitem.attributes.name}}
                                        </a>
                                        <a *ngIf="!subitem.attributes.page.data && !subitem.attributes.category.data && !subitem.attributes.url"
                                            class="dropdown-item dropdown-toggle" id="navbarDropdown1" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            (mouseover)="open($event)">{{subitem.attributes.name}}
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                            <span *ngFor="let subitem2 of subitem.children">
                                                <li tabindex="0">
                                                    <a *ngIf="subitem2.attributes.url && subitem2.attributes.url !='/newsletter'"
                                                        class="dropdown-item" href="{{subitem2.attributes.url}}"
                                                        target="_blank">{{subitem2.attributes.name}}</a>
                                                    <a *ngIf="subitem2.attributes.url && subitem2.attributes.url =='/newsletter'"
                                                        class="dropdown-item"
                                                        routerLink="{{subitem2.attributes.url}}">{{subitem2.attributes.name}}</a>
                                                    <a *ngIf="subitem2.attributes.category.data" class="dropdown-item"
                                                        routerLink="/category/{{subitem2.attributes.category.data.id}}/1">{{subitem2.attributes.name}}</a>
                                                    <a *ngIf="subitem2.attributes.page.data" class="dropdown-item"
                                                        routerLink="/page/{{subitem2.attributes.page.data.id}}">{{subitem2.attributes.name}}</a>
                                                    <a *ngIf="!subitem2.attributes.page.data && !subitem2.attributes.category.data && !subitem2.attributes.url"
                                                        class="dropdown-item">{{subitem2.attributes.name}}</a>

                                                </li>
                                            </span>

                                        </ul>
                                    </li>
                                </span>

                            </ul>
                        </li>

                    </ul>
                    <ul class="navbar-nav mr-auto" style="align-items: center;">
                        <li class="languageBox">
                            <div class="col-sm">
                                <div class="card flex justify-content-center">
                                    <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name"
                                        [style]="{'background-color': '#23527c'}" [showClear]="false"
                                        (onChange)="switchLanguage($event)">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2" style="display:flex"
                                                *ngIf="selectedCountry">
                                                <img src="/assets/{{ selectedCountry.img }}" style="width: 18px" />
                                                <div style="color:white">{{ selectedCountry && selectedCountry.name }}
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-country pTemplate="item">
                                            <div class="flex align-items-center gap-2" style="display:flex">
                                                <img src="/assets/{{ country.img }}" style="width: 18px" />
                                                <div>{{ country.name }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>

                            </div>
                        </li>
                        <!-- <li class="searchBox">
                            <app-search-bar></app-search-bar>
                        </li> -->
                        <!-- <li>
                            <a routerLink="{{url}}"><img alt="chamber-logo"
                                    src="../../../assets/banner espa.jpg" class="img-fluid" width="350px" /></a>
                        </li> -->
                    </ul>

                </div>
            </nav>
        </div>

    </div>
    <div class="row">

        <div class="col-md-12" style="background-color: #EEF0F2">
            <ng-marquee [stopOnHover]="true" speed="drowsy">
                <mark class="item" *ngFor="let item of latestNews"
                    routerLink="/page/{{item.id}}">({{item.attributes.published}}) - {{item.attributes.title}}</mark>

            </ng-marquee>
        </div>

    </div>
</div>