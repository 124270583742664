<div style="display:flex; flex-direction:row; align-items: center;">
    <label for="example-search-input" style="color:transparent">search</label>
    <input 
        class="form-control border-end-0 border rounded-pill" 
        type="search" 
        placeholder="Αναζήτηση.."
        [(ngModel)]="query"
        id="example-search-input"
        (keydown.enter)="search()"
    >
    <button 
        type="submit" 
        class="material-icons" 
        style="background-color: transparent;border:none;color: #babcbe ;"
        (click)="search()"
    >
        search
    </button>
</div>
