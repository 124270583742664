<div class="cont container">
    <div class="row">
        <div class="col-md-9" style="margin-bottom: 1rem;
        margin-top: 1rem;">

            <div id="contact" class="contact-area section-padding">
                <div class="container-fluid">
                    <div class="section-title text-center">
                        <h1 translate>contactUs</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="contact">
                                <form class="form" #form="ngForm">
                                    <div class="row" style="font-size: 1.1rem">
                                        <div class="form-group col-md-6">
                                            <label for="fullName" translate>fullName</label>
                                            <input type="text" name="name" class="form-control" id="fullName"
                                                required="required" ngModel>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="email">Email</label>
                                            <input type="email" name="email" class="form-control" required="required"
                                                ngModel>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="phone" translate>phone</label>
                                            <input type="text" name="phone" class="form-control" required="required"
                                                ngModel>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="subject" translate>subject</label>
                                            <input type="text" name="subject" class="form-control" required="required"
                                                ngModel>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="message" translate>message</label>
                                            <textarea rows="6" name="message" class="form-control" required="required"
                                                ngModel></textarea>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <div class="form-group mt-2">
                                                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel"
                                                    [(ngModel)]="token" required
                                                    [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                                                    (resolved)="statusChanged($event)"></re-captcha>
                                                <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                                                    class="invalid-feedback">
                                                    <div *ngIf="recaptcha.errors?.required" translate>required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <button type="submit" value="Send message" name="submit" id="submitButton"
                                                class="btn btn-contact-bg" title="Submit Your Message!"
                                                (click)="send(form)">Send
                                                Message</button>
                                        </div>
                                       
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!--- END COL -->
                        <div class="col-md-4">
                            <div class="single_address">
                                <i class="material-icons">pin_drop</i>
                                <h4 translate>address</h4>
                                <p translate>officeAddress</p>
                            </div>
                            <div class="single_address">
                                <i class="material-icons">alternate_email</i>
                                <h4>Email</h4>
                                <p>ebear@otenet.gr</p>
                            </div>
                            <div class="single_address">
                                <i class="material-icons">perm_phone_msg</i>
                                <h4 translate>phones</h4>
                                <p>+30 2751067216, +30 2751024595</p>
                            </div>
                            <div class="single_address">
                                <i class="material-icons">alarm</i>
                                <h4 translate>openingHours</h4>
                                <p>ΔΕΥ - ΠΑΡ: 08.00 - 14.00.</p>
                            </div>
                        </div>
                        <div class="col-md-12" style="margin-top:3vh">
                            <div class="videoWrapper">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4599.162176530145!2d22.726218216016413!3d37.63703088904667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfbfe33b291334611!2zzpXOoM6ZzpzOlc6bzpfOpM6XzqHOmc6fIM6RzqHOk86fzpvOmc6UzpHOow!5e0!3m2!1sel!2sgr!4v1662980343419!5m2!1sel!2sgr"
                                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade" style="border:0;" allowfullscreen=""
                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <!--- END COL -->
                    </div>
                    <!--- END ROW -->
                </div>
                <!--- END CONTAINER -->
            </div>


        </div>
        <div class="col-sm-3">
            <app-banners></app-banners>
        </div>
    </div>
</div>