<div class="cont container">
    <div class="row">
        <div class="col-md-9" style="padding: 1vh;padding-top: 3rem;">

            <div id="contact" class="contact-area section-padding">
                <div class="container-fluid">
                    <div class="section-title text-center">
                        <h1 translate>newsletterRegistration</h1>
                    </div>
                    <div class="row" style="justify-content: center;align-items: center;margin-top: 3rem;width: 900px;">
                        <div class="col-md-8">
                            <div class="contact">
                                <form class="form" #form="ngForm">
                                    <div class="row" style="font-size: 1.1rem">
                                        <div class="form-group col-md-12">
                                            <label for="name" translate>name</label>
                                            <input type="text" name="name" class="form-control" id="name"
                                                required="required" ngModel>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="lastname" translate>lastname</label>
                                            <input type="text" name="lastname" class="form-control" id="lastname"
                                                required="required" ngModel>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="afm" translate>afm</label>
                                            <input type="text" name="afm" class="form-control" id="afm"
                                                required="required" ngModel>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="email">Email</label>
                                            <input type="email" name="email" class="form-control" required="required"
                                                ngModel>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="phone" translate>phone</label>
                                            <input type="text" name="phone" class="form-control" required="required"
                                                ngModel>
                                        </div>
                                        <div class="col-md-12 text-center" style="margin-top: 2rem;">
                                            <button type="submit" value="Send message" name="submit" id="submitButton"
                                                class="btn btn-contact-bg" title="Submit Your Message!"
                                                (click)="send(form)" translate>register</button>
                                        </div>
                                       
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!--- END COL -->
                    </div>
                    <!--- END ROW -->
                </div>
                <!--- END CONTAINER -->
            </div>


        </div>
        <div class="col-sm-3">
            <app-banners></app-banners>
        </div>
    </div>
</div>