<div class="cont container">
    <div class="row">
        <div class="col-md-9">

            <h3 class="title" style="margin-top: 2vh;">{{article && article.attributes.title}}</h3>
            <div style="font-size: 1.5vh;padding-left: 0.3rem;"><span translate>published</span> {{article && article.attributes.published}}
            </div>
            <share-buttons stye="margin-top: 2vh;" [theme]="'default'" [include]="['facebook','twitter','linkedin']" [show]="5"
                [url]=websiteUrl></share-buttons>
            <div *ngIf="article && article.attributes.cover.data"
                style="justify-content:center;align-items:center;display: flex;flex-direction: column;margin-top: 3vh;">
                <img [alt]="article.attributes.cover.data.attributes.alternativeText" [src]="cover"
                    style="max-height:25rem" class="img-fluid" />
            </div>


            <div style="margin-top: 3vh;" [innerHTML]="body"></div>

            <div *ngIf="article && article.attributes.files.data" style="margin-top: 3vh;">
                <p class="label" translate>files</p>
                <div *ngFor="let item of article.attributes.files.data">
                    <a [href]="item.attributes.url" class="label">{{item.attributes.name}}</a>
                </div>
            </div>

            <div *ngIf="article && article.attributes.photos.data" style="margin-top: 3vh;margin-bottom: 3vh;">
                <p translate class="label">photos</p>
                <ngx-masonry [ordered]="false" [options]="{gutter: 10}" class="gridd">
                    <div ngxMasonryItem class="masonry-item" *ngFor="let item of article.attributes.photos.data">
                        <a href="{{item.attributes.url}}" target="_blank" style="cursor: pointer;">
                            <img [src]="item.attributes.url" style="overflow: hidden;width: 330px;max-width: 330px; max-height: 250px;height: 250px;object-fit: cover;"/>
                        </a>
                    </div>
                </ngx-masonry>
                <!-- <p-galleria [value]="article.attributes.photos.data" [(visible)]="displayCustom"
                    [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions"
                    [containerStyle]="{'max-width': '850px'}" [numVisible]="7" [circular]="true" [fullScreen]="true"
                    [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.attributes.url" style="width: 100%; display: block;" />
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-item>
                        <div class="grid grid-nogutter justify-content-center">
                            <img [src]="item.attributes.url" style="display: block;" class="imag-fluid" />
                        </div>
                    </ng-template>
                </p-galleria>
                <div *ngIf="article.attributes.photos.data" style="display: flex;flex-direction: row;flex-wrap: wrap;">
                    <div *ngFor="let image of article.attributes.photos.data; let index = index;" class="col-md-2"
                        key="index">
                        <img [src]="image.attributes.url" [alt]="image.attributes.alternativeText"
                            style="cursor: pointer;" class="img-fluid" (click)="imageClick(index)" style="height:9rem"/>
                    </div>
                </div> -->
            </div>
           
        </div>

        <div class="col-sm-3">
            <app-banners></app-banners>
        </div>
    </div>
    <div class="row" style="margin-top: 1rem;">
        <div class="col-md-12">
            <h3>Περισσότερα άρθρα</h3>
        </div>
       
    </div>
    <div class="row" style="margin-top: 2rem;">
        <!-- <div class="col-md-6" *ngFor="let item of moreArticles">
            <div class="container-fluid">
                <div class="row">
                    <div class="picture col-md-5">
                        <img class="img-fluid" [src]="item.cover">
                    </div>
                    <div class="content col-md-7">
                        <h5 class="header">{{item.attributes.title}}</h5>
                        <p class="paragraph">{{item.attributes.short_description}}</p>
                        <a class='link' routerLink="/page/{{item.id}}">Περισσότερα</a>
                    </div>
                </div>

            </div>
        </div> -->
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4" *ngFor="let item of moreArticles">
            <div class="card shadow">
                <div class="card-body text-center" style="height: 28rem;">
                    <a href="#" class="col-12" routerLink="/page/{{item && item.id}}">
                        <img *ngIf="item && item.cover" class="img-fluid" [src]="item.cover"
                            alt="cover_image" style="width:13rem;height: 10rem;">
                    </a>
                    <ul class="col-12 list-inline mt-3">
                        <li class="list-inline-item" translate><i class="fas fa-user"></i>published</li>
                        <li class="list-inline-item"><i class="far fa-clock"></i>{{item &&
                            item.attributes.published}}</li>
                    </ul>
                    <hr>
                    <p class="lead" >{{item && item.attributes.title}}</p>
                    <a class="read_more_btn" role="button"
                        routerLink="/page/{{item && item.id}}" translate>readMore</a>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 2rem;margin-bottom: 2rem;">
        <a class="read_more_btn" style='font-size: 1rem;cursor: pointer;' (click)="backClicked()"
            translate>back</a>
    </div>
</div>